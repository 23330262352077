import { css } from '@emotion/core'
import { breakpoints } from '~/utils/styles'

export const pc_hide = css`
	@media (min-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const container = css`
	padding-top: 40px;
	padding-bottom: 40px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 80px;
		padding-bottom: 80px;
	}
`

export const header_container = css`
	position: relative;
	@media (min-width: ${breakpoints.tablet}px) {
		text-align: center;
	}
`

export const news_title = css`
	margin-top: 10px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
	}
`

export const news_link_text = css`
	position: absolute;
	right: 0;
	bottom: 0;
`

export const list_x_scroll = css`
	display: flex;
	overflow-x: scroll;
	margin-top: 20px;

	:after {
		min-width: 20px;
		height: 1px;
		content: '';
	}

	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
		justify-content: space-between;
		overflow-x: auto;
		
		:after {
			display: none;
		}
	}
`

export const news_card = css`
	margin-right: 10px;
	width: calc(100% - 45px);
	min-width: calc(100% - 45px);
	@media (min-width: ${breakpoints.tablet}px) {
		min-width: auto;
		width: 334px;
	}
`