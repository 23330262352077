import React from 'react'
import { Link } from 'gatsby'
import Typography from '~/components/atoms/Typography'
import NewsCard from '~/components/molecules/NewsCard'
import { getAllMicrocms } from '~/utils/staticQuery'
import { summarize } from '~/utils/seo'
import { container, header_container, list_x_scroll, news_card, news_link_text, news_title, pc_hide } from './styles'
import { Container } from '~/components/layout/Container'

type Props = {
	header_title?: string
}

const NewsList: React.VFC<Props> = ({ header_title, ...props }) => {
	const articleListLength = 3
	const articles = getAllMicrocms(articleListLength)

	return (
		<Container css={container} {...props}>
			<div css={header_container}>
				<Typography variant='subtitle1'>News</Typography>
				{header_title ? (
					<Typography variant='h2' color='textPrimary' css={news_title}>{header_title}</Typography>
				) : (
					<Typography variant='h2' color='textPrimary' css={news_title}>ニュース・<br
						css={pc_hide} />メディア掲載</Typography>
				)}
				<Link to='/times' css={news_link_text}>
					<Typography variant='subtitle2' color='primary'>すべて見る</Typography>
				</Link>
			</div>
			<ul css={list_x_scroll}>
				{articles.map(({ node }) => {
					const description = summarize(node.contents, 60)
					return (
						<NewsCard
							css={news_card}
							title={node.title}
							description={description}
							thumbnail={node.thumbnail.url}
							postDate={node?.postDate ? node.postDate : node.publishedAt}
							newsId={node.newsId}
							key={node.title}
						/>
					)
				})}
			</ul>
		</Container>
	)
}

export default NewsList