import React from 'react'
import Typography from '~/components/atoms/Typography'
import CMSContent from '~/components/molecules/CMSContent'
import SMSlider from '~/components/molecules/SMSlider'
import PCSlider from '~/components/molecules/PCSlider'
import ShareButton from '~/components/molecules/ShareButton'
import ProductFormWithVariation from '~/components/organisms/ProductFormWithVariation'
import NewsList from '~/components/organisms/NewsList'

import {
    container, news,
    pc_slider,
    product_catch, product_description, product_form, product_info,
    product_price,
    product_title,
    product_top, share_button,
    sm_slider, sp_hide
} from './styles'
import { Container } from '~/components/layout/Container'

type Props = {
    data: GatsbyTypes.GiftOptionQuery
}

const getMediaLink = (title: string, links: any[]): string => {
    const media = links.find(link => link.title === title)
    return media.description
}

const Gift: React.VFC<Props> = ({data: { microcmsDailymineral}}) => {
    return (
        <>
            <Container css={[container, product_top]}>
                <SMSlider css={sm_slider}>
                    {microcmsDailymineral.media.map((content) => (
                        <div
                            key={content.image.url}
                        >
                            <img
                                src={content.image.url}
                            />
                        </div>
                    ))}
                </SMSlider>
                <PCSlider css={pc_slider} media={microcmsDailymineral.media} />

                <div>
                    <Typography css={product_catch} variant='subtitle1' color='textSecondary'>大切な人への贈り物に</Typography>
                    <Typography css={product_price} variant='subtitle1'>1包装・¥330 <span>税込</span></Typography>

                    <div css={product_info}>
                        <Typography variant='body2' color='textSecondary' component='p'>
                            ギフト包装をご希望の場合は本商品を同梱してください。<br css={sp_hide}/>
                            1注文につき1つの包装、1配送先まで。単体注文不可。
                            必ず以下の商品説明をご確認下さい。
                        </Typography>
                    </div>

                    <ShareButton
                        css={share_button}
                        fist_link={getMediaLink('twitter', microcmsDailymineral.mediaLinks)}
                        second_link={getMediaLink('line', microcmsDailymineral.mediaLinks)}
                    />
                </div>
            </Container>

            <Container css={[container, product_description]}>
                <CMSContent html={microcmsDailymineral.productDescription} />
            </Container>

            <NewsList />
        </>
    )
}

export default Gift