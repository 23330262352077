import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Gift from '~/components/pages/Gift'

export default function GiftPage({ data }: PageProps<GatsbyTypes.GiftOptionQuery>) {
    return (
        <>
            <Gift
                data={data}
            />
        </>
    )
}

export const query = graphql`
    query GiftOption {
        microcmsDailymineral(handle: {eq: "gift"}) {
            title
            productDescription
            media {
                image {
                    url
                }
            }
            mediaLinks {
                title
                description
            }
        }
    }
`
