import { useStaticQuery, graphql } from 'gatsby'

export const getAllMicrocms = (length?: number) => {
	const maxLength = 100
	const limit = length ? length : maxLength

  const { allMicrocmsNews } = useStaticQuery<GatsbyTypes.allMicocmsArticleQuery>(graphql`
      query allMicocmsArticle {
          allMicrocmsNews(sort: {fields: postDate, order: DESC}) {
              edges {
                  node {
                      title
                      contents
                      newsId
                      thumbnail {
                          url
                      }
                      publishedAt(formatString: "YYYY.MM.DD")
                      postDate(formatString: "YYYY.MM.DD")
                  }
              }
          }
      }
  `)

	return allMicrocmsNews.edges.slice(0, limit)
}