import { css } from '@emotion/core'
import { breakpoints } from '~/utils/styles'
import { palette } from '~/utils/colors'

export const card = css`
	margin-left: auto;
	margin-right: auto;
	width: calc(100% - 40px);
	min-width: calc(100vw - 40px);
	border: 1px solid ${palette.grey['500']};
	border-radius: 12px;
	overflow: hidden;
	position: relative;
	align-self: flex-start;

	@media (min-width: ${breakpoints.tablet}px) {
		min-width: calc(30% - 10px);
		width: calc(30% - 10px);
	}
`

export const body = css`
    padding: 20px;
`

export const text = css`
    margin-top: 10px;
    
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 160%;
`

export const date = css`
    margin-top: 20px;
    font-weight: 500;
`

export const link = css`
	position: absolute;
	right: 20px;
	bottom: 20px;
`