import React, { FC } from 'react'
import { Link } from 'gatsby'
import Typography from '~/components/atoms/Typography'

import { body, card, date, link, text } from './styles'

type Props = {
	title: string
	description: string
	thumbnail: string
	postDate: string
	publishedAt: string
	newsId: string
}

const NewsCard: FC<Props> = ({ title, description, thumbnail, publishedAt, postDate, newsId, ...props }) => (
	<article css={card} {...props}>
		<figure>
			<div><img src={thumbnail} alt={title} loading='lazy'/></div>
		</figure>
		<div css={body}>
			<Typography variant='h5' component='h3' color='textPrimary'>{title}</Typography>
			<Typography component='p' css={text} dangerouslySetInnerHTML={{__html: description}} />
			<Typography variant='subtitle2' component='p' color='textSecondary' css={date}>{postDate}</Typography>
			<Link to={`/times/${newsId}`} css={link}>
				<Typography variant='subtitle2' component='p' color='primary'>記事を読む</Typography>
			</Link>
		</div>
	</article>
)

export default NewsCard