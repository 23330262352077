import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const content = css`
    h1, h2 {
	    margin-top: 20px;
	    color: ${palette.text.primary};
	    font-weight: bold;
	    font-size: 2.4rem;
	    line-height: 31px;

	    :first-of-type {
		    margin-top: 0;
	    }

	    @media (min-width: ${breakpoints.tablet}px) {
		    margin-top: 40px;
		    font-size: 3.2rem;
		    line-height: 41px;
	    }
    }
    
    h3 {
	    margin-top: 10px;
	    font-size: 1.8rem;
        font-weight: bold;
        line-height: 31px;
	    color: ${palette.text.primary};
	    @media (min-width: ${breakpoints.tablet}px) {
		    margin-top: 18px;
		    font-size: 2.4rem;
		    line-height: 31px;
	    }
    }

	li, p {
		margin-top: 10px;
		font-weight: 500;
		font-size: 1.6rem;
		line-height: 26px;

		@media (min-width: ${breakpoints.tablet}px) {
			margin-top: 20px;
			font-size: 2rem;
			line-height: 32px;
		}
	}

	a {
		font-weight: 500;
		color: ${palette.text.secondary};
		text-decoration: underline;
		word-break: break-all;

		:hover {
			color: ${palette.primary.light};
		}
	}

	strong {
		font-weight: bold;
	}

	// 階層のリストスタイル
	& > ol {
		list-style-position: outside;
		list-style-type: decimal;
		padding-left: 3rem;

		ol {
			list-style-position: inside;
			list-style-type: lower-latin;

			> li {
				text-indent: 2rem;
			}
		}
	}

	& > ul {
		list-style-position: outside;
		list-style-type: disc;
		padding-left: 2rem;
	}
    
    // 動画など
    iframe {
        margin-top: 10px;
        width: 100%;
        min-height: 230px;
	    @media (min-width: ${breakpoints.tablet}px) {
		    margin-top: 20px;
		    min-height: 380px;
	    }
    }
    
    // 引用
	blockquote {
		padding: 12px 15px;
        border-radius: 4px;
        background-color: ${palette.grey['500']};
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 25.6px;
		@media (min-width: ${breakpoints.tablet}px) {
			padding: 20px 32px;
		}
    }
`