import { css } from '@emotion/core'
import { breakpoints } from '~/utils/styles'
import { palette } from '~/utils/colors'

export const container = css`
	@media (min-width: ${breakpoints.tablet}px) {
		display: flex;
	}
	
	a {
		@media (min-width: ${breakpoints.tablet}px) {
			width: 50%;
		}
	}
`

export const btn = css`
	position: relative;
	background-color: ${palette.grey['500']};
	padding: 9px 12px;
	font-size: 1.4rem;
	@media (min-width: ${breakpoints.tablet}px) {
		padding: 11px 16px;
		font-size: 1.6rem;
	}
	
	:hover {
		color: ${palette.primary.main};
		span:after {
			color: ${palette.primary.main};
		}
	}
`

export const btn_left = css`
	margin-bottom: 1px;
	border-radius: 8px 8px 0 0;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-bottom: 0;
		margin-right: 1px;
		border-radius: 8px 0 0 8px;
	}
`

export const btn_right = css`
	border-radius: 0 0 8px 8px;
	@media (min-width: ${breakpoints.tablet}px) {
		border-radius: 0 8px 8px 0;
	}
`

export const icon = css`
	:after {
		color: ${palette.text.secondary};
		position: absolute;
		font-size: 2rem;
		right: 16px;
		top: calc(50% - 1rem);
	}
`