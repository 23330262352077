import React from 'react'
import Typography from '~/components/atoms/Typography'
import TwitterIcon from '~/components/molecules/TwitterIcon'
import LineIcon from '~/components/molecules/LineIcon'
import { container, btn, btn_left, btn_right, icon } from './styles'

type Props = {
	fist_link: string
	second_link: string
}

const ShareButton: React.VFC<Props> = ({ fist_link, second_link, ...props }) => {
	return (
		<div css={container} {...props}>
			<a href={fist_link} target='_blank' rel='noopener noreferrer'>
				<Typography css={[btn, btn_left]}>Tweetする<TwitterIcon css={icon}/></Typography>
			</a>
			<a href={second_link} target='_blank' rel='noopener noreferrer'>
				<Typography css={[btn, btn_right]}>LINEで送る<LineIcon css={icon}/></Typography>
			</a>
		</div>
	)
}

export default ShareButton