import React, { FC } from 'react'
import Icon from '~/components/atoms/Icon'
import { icon } from './styles'

const LineIcon: FC = ({ ...props }) => {
	return (
		<Icon css={icon} {...props} />
	)
}

export default LineIcon