import React from 'react'
import { content } from './styles'

type Props = {
	html: string
}

const CMSContent: React.VFC<Props> = ({ html, ...props }) => {
	return (
		<article
			{...props}
			css={content}
			dangerouslySetInnerHTML={{ __html: html }}
		/>
	)
}

export default CMSContent